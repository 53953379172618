$gutter: 8px;

div.MuiGrid-root.MuiGrid-item {
	padding: $gutter;
}

div.MuiContainer-maxWidthLg {
	max-width: $screen-xl;
}

div.MuiGrid-container {
	width: calc(100% + #{$gutter*2});
  margin: -$gutter;
}

div.MuiContainer-root {
  padding-left: $gutter*2;
  padding-right: $gutter*2;

	@media (min-width: $screen-sm) {
	  padding-left: $gutter*5;
	  padding-right: $gutter*5;
	}
}

div.MuiContainer-root div.MuiContainer-root {
	padding-left: 0;
	padding-right: 0;
}
