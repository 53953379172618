.header {
	position: relative;

	.MuiContainer-root,
	.MuiGrid-container {
		height: 100%;
	}

	&__left,
	&__right {
		display: inline-block;
		max-width: 98px;
		letter-spacing: -0.02em;

		@media (min-width: $screen-sm) {
			max-width: inherit;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	&__left:not(:last-child) {
		margin-right: 1em;
	}

	&__right:not(:last-child) {
		margin-right: 1em;
	}

	&__center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		img,
		svg {
			max-height: 70px;

			@media (min-width: $screen-sm) {
				max-height: 80px;
			}
		}
	}
}
