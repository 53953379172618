.fixed-user-bar {
	position: relative;
	z-index: 2;
	text-align: center;
	font-size: $font-size-xxs;
	display: flex;
	justify-content: center;
  align-items: baseline;
	background: white;
	padding: 1em;
	// font-weight: 600;

	.btn {
		color: white;
		font-weight: 600;
		cursor: pointer;
		padding: 0.5em 1em;
		background: $color-brand;

		&--warning {
			background: $color-warning;
		}

		&:hover {
			text-decoration: underline;
		}
	}

	p,
	.btn {
		margin: 0 1em 0 0;
	}
}
