.align-center {
	text-align: center;

	button {
		display: block;
		margin: 0 auto;
	}
}

.post-message {
	text-align: left;
	white-space: pre-line;
}

h2.last {
	margin-top: 3em;
}

.clear-input-field {
	border: none;
	color: $color-brand;
	transition: all .3s ease;
	font-size: $font-size-xs;
	opacity: 0.5;

	&:hover {
		opacity: 1;
		cursor: pointer;
	}
}

.content-wrapper {
	background: white;
	padding: 6vw;
	box-shadow: $shadow;
	overflow: hidden;
	white-space: pre-line;

	@media (min-width: $screen-md) {
		padding: 2vw;
	}
}

.create-step {
	margin: 0;
	font-size: $font-size-lg;
	line-height: 1.2em;

	@media (min-width: $screen-sm) {
		font-size: $font-size-xl;
	}
}

hr {
	margin: 40px 0;
	box-shadow: none;
	height: 1px;
	border: 0;
	background: $color-line;

	@media (min-width: $screen-sm) {
		margin: 80px 0;
	}

	&.sm {
		margin: 40px 0;
	}
}

.center {
	text-align: center;
}

.tandem-nautile-details {
	text-align: left;
	margin-top: 40px;
}

.underline {
	padding-bottom: 10px;
	border-bottom: 1px solid $color-line;
}

.not-found {
	margin-top: 40px;

	h1 {
		margin: 0;
		font-size: $font-size-xl;

		@media (min-width: $screen-sm) {
			font-size: $font-size-xxl;
		}
	}
}

a {
	width: max-content;
}

.hide-mobile {
	display: none;

	@media (min-width: $screen-md) {
		display: inline-block;
	}
}

.flex {
	display: flex;
}

.space-between {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

div.helper-grid {
	justify-content: initial;
	margin-top: 2em;
	margin-bottom: 2em;

	@media (min-width: $screen-md) {
		justify-content: space-between;
		align-items: center;
	}

	& > div:last-child {
		text-align: center;

		a {
			display: block;

			@media (min-width: $screen-sm) {
				margin-left: auto;
			}
		}
	}
}

.align-right {
	margin-left: auto;
}

.load-more-wrapper {
	margin-top: 40px;
}

.image-wrapper {
	overflow: hidden;

	img {
		width: 100%;
	}
}
