.modal {
	display: flex;
	justify-content: center;
	align-items: center;

	&__inner {
		background: white;
		padding: 2em;
		width: 100%;
		margin: 20px;
		align-self: flex-end;
		text-align: center;

		@media (min-width: $screen-sm) {
			align-self: center;
			max-width: 500px;
			padding: 4em;
		}

		h2 {
			margin: 0 0 2em;
		}
	}

	&__buttons {
		display: flex;
		justify-content: center;
		align-items: center;

		button:first-child {
			margin-right: 0.5em;
		}
	}
}
