.panel {

	button,
	.MuiButton-label {
		font-size: $font-size-xxs;
	}

	h2 {
		font-size: $font-size-lg;
		font-weight: 500;
	}

	&__post {
		display: block;
		border-bottom: 1px solid #bbb;
		margin: 0 0 20px 0;
		padding: 0 0 20px 0;
		font-size: $font-size-sm;

		@media (min-width: $screen-md) {
			margin: 0 0 10px 0;
			padding: 0 0 10px 0;
		}

		div.MuiGrid-root.MuiGrid-item {
			padding: 1px 8px;

			@media (min-width: $screen-md) {
				padding: 8px;
			}
		}

		&:last-child {
			border: none;
		}

		a {
			transition: opacity .3s ease;

			&:hover {
				opacity: 0.5;
			}
		}

		p,
		a {
			display: block;
			margin: 0;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.MuiGrid-container {
			align-items: center;
		}
	}
}