@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;600&display=swap');

@import
	'_variables.scss',
	'_base.scss',
	'_typo.scss',
	'_grid.scss',
	'_sections.scss',
	'_footer.scss',
  '_banner.scss',
	'_header.scss',
	'_modal.scss',
	'_form.scss',
	'_posts.scss',
	'_userbar.scss',
	'_panel.scss',
	'_meta.scss',
	'_helpers.scss',
	'_lazyload.scss',
	'_tabs.scss',
	'_spinner.scss',
	'_animations.scss';


.MuiIconButton-colorSecondary {
	color: $color-warning !important;
}

.MuiIconButton-colorPrimary,
.MuiButton-textPrimary,
.MuiButton-outlinedPrimary,
.MuiCircularProgress-colorPrimary {
	color: $color-brand !important;
}
.MuiButton-outlinedPrimary {
	border-color: $color-brand !important;
}
.MuiButton-containedPrimary {
	background: $color-brand !important;
}
.MuiButton-label {
	text-transform: initial;
}
.MuiButton-root.MuiButton-contained {
	padding: 10px 22px !important;
}