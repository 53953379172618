h1,
h2,
h3 {
	font-weight: 400;
}

h1,
h1.MuiTypography-h1 {
	margin: 0 0 1em;
	font-size: $font-size-lg;
	font-weight: 600;
	font-family: 'Manrope', sans-serif;
	line-height: 1.2em;

	@media (min-width: $screen-sm) {
		line-height: 1.4em;
		font-size: $font-size-xl;
	}
}

h2,
h2.MuiTypography-h2 {
	margin: 0 0 1em;
	font-size: $font-size-lg;
	font-weight: 500;
	font-family: 'Manrope', sans-serif;
	line-height: 1.2em;

	@media (min-width: $screen-sm) {
		line-height: 1.4em;
	}
}

p.MuiTypography-body1,
p {
	font-family: 'Manrope', sans-serif;
	margin: 0 0 1em;
	line-height: 1.6em;

	&:last-child {
		margin: 0;
	}
}

p a	{
	transition: opacity .3s ease;
	text-decoration: underline;

	&:hover {
		opacity: 0.5;
	}
}

p.lg,
a.lg {
	font-size: $font-size-sm;
	display: inline-block;
	max-width: 100%;

	@media (min-width: $screen-md) {
		font-size: $font-size-lg;
	}
}

h2.about-sec {
	font-family: 'Manrope', sans-serif;
	margin: 3em 0 1em;
	font-size: $font-size-md;
	// font-weight: bold;
}

.headline-wrapper {
	margin-bottom: 40px;

	h1 {
		margin: 0;
	}
	p {
		font-size: $font-size-md;
	}
}
