$color-brand: #4F4240;
$color-placeholder: #aaa;
$color-brand-light: #eeeeee;
$color-brand-lighter: #F7F3F2;
$color-warning: #F46969;
$color-back: #DAD7D6;
$color-white: white;

$color-line: $color-placeholder;

$border-radius: 6px;

$font-size-xxl: 64px;
$font-size-xl: 32px;
$font-size-lg: 24px;
$font-size-md: 18px;
$font-size-sm: 16px;
$font-size-xs: 14px;
$font-size-xxs: 12px;

$screen-xl: 1920px;
$screen-lg: 1280px;
$screen-md: 960px;
$screen-sm: 600px;
$screen-xs: 576px;
$screen-xxs: 375px;

$shadow: 0 4px 8px -4px rgba(0, 0, 0, 0.4);
