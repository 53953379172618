.footer {
	margin-top: calc(160px + 4vw);
	// border-top: 1px solid $color-brand;
	position: relative;
	// background: $color-brand-lighter;
	line-height: 1.6em;

	&:after {
		content: '';
		display: block;
		background: url(../images/graphic-waves.svg) repeat-x transparent;
		background-size: contain;
		position: absolute;
		top: -28px;
		height: 30px;
		width: calc(100% + 60px);
		z-index: 1;
	}

	button,
	a {
		margin: 0 1em 0 0;
		display: block;

		@media (min-width: $screen-md) {
			display: inline-block;
		}
	}

	&__languages {
		margin: 1em 0;

		p {
			margin: 0;
		}
	}

	&__logo-wrapper {
		display: block;
		margin-bottom: 2em;

		@media (min-width: $screen-xl) {
			display: inline-block;
			margin-bottom: 0;
		}

		a.footer__logo:first-child {
			margin-left: 0 !important;
		}

		&:first-child {
			margin-right: 4em;
			padding-right: 4em;

			@media (min-width: $screen-md) {
				a:last-child {
					&:after {
						content: '';
						display:	block;
						height: 100%;
						width: 1px;
						background: $color-brand;
						right: -4em;
						top: 0;
						position: absolute;
					}
				}
			}
		}

		p {
			display: block;
			margin: 0 0 1em;
		}
	}

	&__lang {
		display: block;
		transition: all .3s ease;

		@media (min-width: $screen-md) {
			display: inline-block;
			margin-right: 1em;
		}

		&:hover {
			cursor: pointer;
			opacity: 0.5;
		}

		&.active {
			text-decoration: underline;
			opacity: 1;
		}
	}

	&__logos {

		@media (min-width: $screen-md) {
			display: flex;
		}

		@media (min-width: $screen-xl) {
			display: flex;
			justify-content: flex-end;
		}
	}

	a.footer__logo {
		display: block;
		margin: 0 0 1em;
		position: relative;

		@media (min-width: $screen-md) {
			display: inline-block;
			margin: 0 0 0 1.5em;
		}

		@media (min-width: $screen-lg) {
			margin: 0 0 0 3em;
		}

		img {
			max-height: 40px;
			max-width: 100px;
			display: block;
			position: relative;

			@media (min-width: $screen-md) {
				max-height: 40px;
				max-width: 110px;
			}

			@media (min-width: $screen-lg) {
				max-height: 50px;
				max-width: 150px;
			}
		}
	}

	div.MuiContainer-root {
		position: relative;
	}

	.MuiGrid-container {
		margin-bottom: 4em;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.peephole {
		position: absolute;
		height: 160px;
		overflow: hidden;
		top: -170px;
		right: 10%;

		#tube {
			fill: $color-brand;
		}
	}

	&__copyright {
		font-size: $font-size-xs;
		opacity: 0.5;
	}
}
