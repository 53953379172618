::-webkit-input-placeholder { /* Edge */
  color: $color-placeholder;
  font-family: 'Manrope', sans-serif;
}

:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: $color-placeholder;
  font-family: 'Manrope', sans-serif;
}

::placeholder {
  color: $color-placeholder;
  font-family: 'Manrope', sans-serif;
}

.placeholder {
	color: $color-placeholder;
	font-weight: 400;
	font-family: 'Manrope', sans-serif;
}

div.MuiFormControl-root {
	margin: 0;
	display: flex;
}

.form-field {
	margin-bottom: 20px;

	@media (min-width: $screen-sm) {
		margin-bottom: 30px;
	}

	.MuiButton-outlined {
		border: none;
		background: white;
		width: 100%;
		padding: 1em;
		box-sizing: border-box;
		font-size: $font-size-sm;
	}

	.MuiButton-label {
		text-align: left;
		text-transform: none;
		justify-content: left;
		font-size: $font-size-sm;
	}
}

.MuiGrid-item .form-field {
	margin-bottom: 10px;
}

span.MuiButton-label {
	font-family: 'Manrope', sans-serif;
}

fieldset.MuiOutlinedInput-notchedOutline,
div.MuiOutlinedInput-root,
div.MuiInputBase-root.MuiOutlinedInput-root,
span.MuiButtonBase-root {
	border: none;
	border-radius: 0;
	box-shadow: none;

	&:hover {
		border: none;
	}
}

.form {

	.MuiInputBase-input,
	&__input {
		box-shadow: none;
		border: 1px solid $color-white;
		border-radius: 0;
		background: $color-white;
		width: 100%;
		padding: 1em;
		box-sizing: border-box;
		font-size: $font-size-sm;
		height: auto;
		line-height: 1.4em;

		&:focus,
		&:active {
			border: 1px solid $color-brand;
			outline: none;
		}
	}

	&__error {
		color: $color-warning;
		margin: 0.3em 0 0 0;
		display: block;
	}

	&__checkbox {
		display: flex;

		input {
			display: inline-block;
		}
	}
	&__checkbox-text {
		display: inline-block;
		margin-left: 10px;

		a {
			display: inline-block;
			margin-right: 1em;
		}
	}
}

label,
label > div > span {
	margin-bottom: .3em;
  display: block;
  font-size: $font-size-xs;

  @media (min-width: $screen-sm) {
  	margin-bottom: .5em;
  }
}

.file-upload-button {
	height: 56px;
	width: 100%;
}

button.MuiButtonBase-root {
	border-radius: 6px;
}

button span.MuiButton-label {
	font-weight: 600;
}

.MuiButton-root.MuiButton-contained {
	padding: 14px 30px;
}

form button.submit-button {
	margin-top: 4em;
}

textarea:disabled,
input:disabled,
label .Mui-disabled {
  background: #dddddd;
  opacity: 0.2;
}
