.lazyload {
  min-width: 100%;
  min-height: 100%;
  max-width: unset;
  display: block;
  transition: all 1s ease;
}

.lazyload,
.lazyloading  {
  opacity: 0;
  transform: scale(1.025);
}

.lazyloaded {
  transition: all 0.6s ease;
  opacity: 1;
  transform: scale(1);
  // filter: blur(0);
}
