.footer:after {
	animation: waves 8s infinite linear;
	transform-origin: center;
	transform-box: fill-box;
}

@keyframes waves {
	0% {
		transform: translate(0px, 0);
	}
	50% {
		transform: translate(-30px, 4px);
	}
	100% {
		transform: translate(-60px, 0);
	}
}

#peephole {
	animation: peephole 4s infinite alternate;
	transform-origin: center;
	transform-box: fill-box;
}

@keyframes peephole {
	from {
		transform: translateY(10px);
	}
	to {
		transform: translateY(20px);
	}
}


#bubble-1 {
	animation: bubble-1 6s infinite ease;
	transform-origin: center;
	transform-box: fill-box;
}

@keyframes bubble-1 {
	0% { opacity: 0; }
	48% {
		opacity: 0;
		transform: translate(12px, 12px) scale(0);
	}
	68% { opacity: 1; }
	88% {
		transform: translate(12px, 0px) scale(1);
		opacity: 0;
	}
	100% { opacity: 0; }
}

#bubble-2 {
	animation: bubble-2 6s infinite ease;
	transform-origin: center;
	transform-box: fill-box;
}

@keyframes bubble-2 {
	0% { opacity: 0; }
	44% {
		opacity: 0;
		transform: translate(0px, 52px) scale(0);
	}
	64% { opacity: 1; }
	84% {
		transform: translate(0px, 40px) scale(1);
		opacity: 0;
	}
	100% { opacity: 0; }
}

#bubble-3 {
	animation: bubble-3 6s infinite ease;
	transform-origin: center;
	transform-box: fill-box;
}

@keyframes bubble-3 {
	0% { opacity: 0; }
	40% {
		transform: translate(14px, 82px) scale(0);
		opacity: 0;
	}
	60% { opacity: 1; }
	80% {
		transform: translate(14px, 70px) scale(1);
		opacity: 0;
	}
	100% { opacity: 0; }
}
