.tabs {
  display: flex;
  border-bottom: 1px solid $color-line;
  margin: 0 0 2em;

  &__item {
    padding: 1em 2em;
    cursor: pointer;
    border-bottom: 3px solid $color-back;
    transition: all 0.2s ease;

    &.active {
      border-bottom: 3px solid $color-brand;
      // font-weight: 600;
      color: $color-brand;
    }
  }
}
