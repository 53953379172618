html,
body {
	height: 100%;
  margin: 0;
  padding: 0;
  color: $color-brand;
  font-size: $font-size-sm;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: $color-back;
}

body {
	overflow-x: hidden;
}

#root {
	display: flex;
	flex-direction: column;
	// justify-content: space-between;
	min-height: 100%;
	overflow-x: hidden;
}

::selection {
	-webkit-text-fill-color: white;
	background-color: $color-brand;
}

a {
	text-decoration: none;
	color: $color-brand;
}

svg,
img {
	max-width: 100%;
	display: block;
}

.button-wrapper {
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.MuiButton-outlined {
		margin-left: 1em;
	}
}

.empty-state {
	opacity: 0.5;
	font-style: italic;
}
