.banner {
  display: block;
  padding: 16px;
  margin: 0 0 26px;
  text-align: center;
  background: $color-white;

  @media (min-width: $screen-sm) {
    padding: 26px;
  }

  p {
    font-size: $font-size-sm;

    @media (min-width: $screen-sm) {
      font-size: $font-size-md;
    }
  }
}
