.post {
	display: block;
	position: relative;
  width: 100%;
	overflow: hidden;
	background: $color-brand;
	position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  transition: all .3s ease;

	&:hover {
		opacity: 1;
		box-shadow: $shadow;
		transform: scale(1.005);
		z-index: 20;

    .post__title,
    .post__name {
      text-decoration: underline;
    }

		.post__media.post__media--has-img {
			transform: scale(1.02);
		}
	}

	&__wrapper {
	  background-color: white;
	  width: 100%;
	  padding-top: 100%; /* 1:1 Aspect Ratio */
	  position: relative; /* If you want text inside of it */
	}

	&__unpublish {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 2;
	}

	&__inner {
	  z-index: 1;
	  height: 100%;
	  font-size: 25px;
	  color: white;
	  position: relative;
	  display: flex;
	  flex-direction: column;
	}

	&__media {
		color: $color-brand;
		font-size: $font-size-xs;
		position: relative;
		height: calc(100% - 77px);
		overflow: hidden;
		transition: transform 0.3s ease-out;

		p {
			margin: 16px;
			overflow: hidden;
		}
	}

	&__media--has-text {
		background: $color-brand-lighter;
	}

	&__meta {
		background: $color-white;
		color: $color-brand;
		font-size: $font-size-xs;
		padding: 10px;
		z-index: 1;
		border-top: 1px solid $color-line;

		* {
			line-height: 1.4em;
		}
	}

	&__image {
		height: 100%;

		img {
			max-width: unset;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&__sound {
		background: url('../images/graphic-sound.svg') $color-brand center no-repeat;
		height: 100%;
	}

	&__video {
		background: url('../images/graphic-video.svg') $color-brand center no-repeat;
		height: 100%;
	}

	&__name {
		font-weight: bold;
		margin: 0 15px 0 0;
	  display: -webkit-box;
	  -webkit-line-clamp: 1;
	  -webkit-box-orient: vertical;
	  overflow: hidden;
	}

	&__title {
		font-weight: bold;
	  display: -webkit-box;
    position: relative;
	  -webkit-line-clamp: 1;
	  -webkit-box-orient: vertical;
	  overflow: hidden;
    margin: 0;

    // &:after {
    //   content: '';
    //   display: block;
    //   position: absolute;
    //   width: 100%;
    //   height: 1px;
    //   background: black;
    //   bottom: 0;
    // }
	}

  &__bottom {
    display: flex;
    justify-content: space-between;
  }

	&__location {
	  display: -webkit-box;
	  -webkit-line-clamp: 1;
	  -webkit-box-orient: vertical;
	  overflow: hidden;
    margin: 0 15px 0 0;
	}

	&__date {
		// color: green;
	}
}

.post-detail {

	&__header {
		margin-bottom: 2em;

		p {
			margin: 0;
		}
	}

	&__free-message-wrapper {
		margin: 100px 0 0;
		text-align: left;

		h3 {
			margin: 0 0 0.5em;
		}
	}

	&__free-message {
		background: $color-brand-lighter;
		padding: 30px;
		white-space: pre-line;
		text-align: left;
	}
}
